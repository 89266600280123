import React from 'react'
import '../partner/partner.css'
import { Row, Col } from 'reactstrap'
import melbourne from '../../assets/img/partner-level-1/melpoly.png'
import canadian from '../../assets/img/partner-level-1/canadian.png'
import oxford from '../../assets/img/partner-level-1/oxford.png'
import swinburn from '../../assets/img/partner-level-1/swinburne.png'
import victoriaSydney from '../../assets/img/partner-level-1/victoria-sydney.png'
import victoriaBrisbane from '../../assets/img/partner-level-1/victoria-brisbane.png'
import tasmania from '../../assets/img/partner-level-1/tasmania.png'
import sunshine from '../../assets/img/partner-level-1/sunshise.png'
import canberra from '../../assets/img/partner-level-1/canberra-hils.png'
import griffith from '../../assets/img/partner-level-1/griffith.png'
import cityMalaysia from '../../assets/img/partner-level-1/city-malaysia.png'
import tus from '../../assets/img/partner-level-1/tus.png'
import woosong from '../../assets/img/partner-level-1/wosoong.png'
import victoriaMelbourne from '../../assets/img/partner-level-1/victoria-melbourne.png'
import northernLight from '../../assets/img/partner-level-1/northern.png'


const Partner = () => {
    return (
        <section className="partner" id='lokasi'>
            <Row className='d-flex justify-content-center mt-5 mb-5'>
                <Col lg='3' xs='3'>
                    <h3 style={{textAlign: 'center'}}>Platinum Partner</h3>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg='1' xs='2'>
                    <img className='img-fluid' src={melbourne} />
                </Col>
                <Col lg='1' xs='2'>
                    <img className='img-fluid' src={canadian} />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-5'>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={oxford} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={swinburn} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={victoriaSydney} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={victoriaMelbourne} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={victoriaBrisbane} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter' >
                    <img className='img-fluid' src={tasmania} />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-5 mb-5'>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={sunshine} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={canberra} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={griffith} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={cityMalaysia} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={tus} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={woosong} />
                </Col>
                <Col lg='1' xs='2' className='card-platinum-parnter'>
                    <img className='img-fluid' src={northernLight} />
                </Col>
            </Row>
        </section>
    )
}

export default Partner