import React from 'react'
import '../about/about.css'
import tiketGratis from '../../assets/img/level1-2024/assets-11.png'
import why1 from '../../assets/img/level1-2024/icon-01.png'
import why2 from '../../assets/img/level1-2024/icon-02.png'
import why3 from '../../assets/img/level1-2024/icon-03.png'
import why4 from '../../assets/img/level1-2024/icon-04.png'

const About = () => {
  return (
    <>
        <div className="about mb-5" id='about'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-8 col-md-8'>
                    <img className='img-fluid-about judul-about' src={tiketGratis} />
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-3 col-md-3 d-flex justify-content-center'>
                    <div>
                        <img className='img-fluid' src={why1} />
                        <h2>Konsultasi Gratis</h2>
                        <p>Tanya apapun, info kampus biaya kuliah, beasiswa, part-time job, dll</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 d-flex justify-content-center'>
                    <div>
                        <img className='img-fluid' src={why2} />
                        <h2>Banyak Pilihan Kampus</h2>
                        <p>Datang jauh-jauh dari banyak negara special untuk kamu</p>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-3 col-md-3 d-flex justify-content-center'>
                    <div>
                        <img className='img-fluid' src={why3} />
                        <h2>Workshop Gratis</h2>
                        <p>Upgrade Skillmu, ikuti workshop dengan pembicara yang kompeten</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 d-flex justify-content-center'>
                    <div>
                        <img className='img-fluid' src={why4} />
                        <h2>Game dan Hiburan</h2>
                        <p>Selesaikan challenge bersama temanmu, rebut hadiahnya</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default About